<template>
  <div class="main-wrapper">
    <div class="page-wrapper full-page">
      <a href="#" class="noble-ui-logo d-block mx-4 mt-4">
        <img src="@/assets/images/wappin-logo.png" height="40" />
      </a>
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'DefaultLayout',
};
</script>

<style lang="scss" scoped>
.page-wrapper {
  // background: url('~@/assets/images/auth-bg.png') no-repeat left center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
</style>
